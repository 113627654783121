<template>
    <div>
        <CompTable title="机构首页设置" ref="comp_table" :columns="columns" table-api="/gateway/org/pc/organization/para/listOrgAppInfoParam?dimensionId=">
            <template #search="evt">
                <Select v-model="evt.search.dimensionId" placeholder="选择组织纬度" style="width:200px">
                    <Option v-for="item in dimensionList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
                <Input v-model="evt.search.keyword" placeholder="关键词搜索" style="width:200px"></Input>
            </template>
            <template #operate>
                <Button type="info" @click="$refs.model.display()">新增</Button>
            </template>
        </CompTable>

        <CompModal class="organization-home-set-up" ref="model" title="选择应用" width="100%" @on-close="onCloseModal">
            <template #head-operate>
                <div class="modal-operate-btn" @click="onSubmitSelectApp">保存</div>
            </template>
            <div class="model-content">
                <div class="dimension-box" v-if="!edit_org">
                    <div class="select-box" @click="$refs.select_org.display()">
                        <p v-if="selected_org">{{ selected_org.orgName }}</p>
                        <p class="empty" v-else>选择机构</p>
                        <Icon type="ios-arrow-down" />
                    </div>
                </div>
                <div class="model-table">
                    <div class="table-item">
                        <CompTable ref="app_table" :isIndex="false" :pathRules="false" :isToolbar="false" isRadio isSelector :columns="app_columns" table-api="/gateway/apps/basic/api/base/finger/pc/appInfo/listAppInfoByPage" @on-change-selection="onChangeSelection">
                            <template #search="evt">
                                <Input v-model="evt.search.appName" placeholder="应用名称" style="width:200px"></Input>
                                <Input v-model="evt.search.appCode" placeholder="应用编码" style="width:200px"></Input>
                            </template>
                        </CompTable>
                    </div>
                    <div class="table-item">
                        <CompTable ref="page_table" :isIndex="false" :pathRules="false" :isToolbar="false" isRadio isSelector :columns="page_columns" :dataList="page_data" :dataBefore="onPageDataBefore">
                            <template #search="evt">
                                <Input v-model="evt.search.value" placeholder="页面名称或编码" style="width:200px"></Input>
                            </template>
                        </CompTable>
                    </div>
                </div>
            </div>
        </CompModal>

        <SelectOrg ref="select_org" @on-submit="onSubmitOrg"></SelectOrg>
    </div>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"
import CompModal from "../../residentdatabase/components/CompModal.vue"
import SelectOrg from "../../fastcustomization/children/SelectOrg.vue"

export default {
    components: { CompTable, CompModal, SelectOrg },

    data() {
        return {
            page_data: [],
            dimensionList: [],
            selected_org: null,
            edit_org: null,

            columns: [
                {
                    title: "组织名称",
                    key: "orgName",
                },
                {
                    title: "机构组织编码",
                    key: "orgCode",
                },
                {
                    title: "所属组织纬度",
                    key: "dimensionName",
                },
                {
                    title: "关联应用名称",
                    key: "param.appName",
                },
                {
                    title: "应用编码",
                    key: "param.appCode",
                },
                {
                    title: "页面名称及编码",
                    render: (h, params) => params.row.param.name + " - " + params.row.param.code,
                },
                {
                    title: "启禁用",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("i-switch", {
                            props: {
                                value: params.row.status === "1" ? true : false,
                            },
                            on: {
                                "on-change": res => {
                                    // 延迟 500ms 执行，避免导致动画卡顿
                                    setTimeout(() => {
                                        Request.post(
                                            "/gateway/org/pc/organization/updateOrganizationByOrgId",
                                            {
                                                status: res ? "1" : "2",
                                                orgId: params.row.orgId,
                                            },
                                            { json: true }
                                        ).catch(() => {
                                            // 失败时取反值
                                            params.row.status = res ? "2" : "1"
                                        })
                                    }, 500)
                                },
                            },
                        })
                    },
                },
                {
                    title: "操作",
                    fixed: "right",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "Button",
                            {
                                props: {
                                    type: "info",
                                    size: "small",
                                },
                                on: {
                                    click: () => {
                                        this.edit_org = params.row.orgId
                                        this.$refs.model.display()
                                    },
                                },
                            },
                            "设置"
                        )
                    },
                },
            ],

            app_columns: [
                {
                    title: "应用名称",
                    key: "appName",
                    width: 200,
                },
                {
                    title: "应用编码",
                    key: "appCode",
                    width: 200,
                },
            ],

            page_columns: [
                {
                    title: "页面名称",
                    key: "name",
                    width: 150,
                },
                {
                    title: "页面编码",
                    key: "code",
                    width: 150,
                },
                {
                    title: "页面 Action",
                    key: "action",
                    width: 200,
                },
            ],
        }
    },

    mounted() {
        this.getDimensionList()
    },

    methods: {
        /**
         * 获取纬度列表
         */
        getDimensionList() {
            Request.get("/gx/pc/dimension/selectExceptOneByOemCode", {
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                this.dimensionList = res
            })
        },

        /**
         * 保存选择的应用
         */
        onSubmitSelectApp() {
            const app = this.$refs.app_table.getSelection()?.[0]

            if (!app) {
                return this.$Message.error("请选择应用")
            }

            const page = this.$refs.page_table.getSelection()?.[0]

            if (!page) {
                return this.$Message.error("请选择页面")
            }

            const orgId = this.selected_org?.orgId || this.edit_org

            if (!orgId) {
                return this.$Message.error("请选择机构")
            }

            Request.post(
                "/gateway/org/pc/organization/para/addOrgAppInfoParam",
                {
                    orgId: orgId,
                    paraCode: "orgAppInfo",
                    optionVos: [
                        {
                            optionName: "appName",
                            optionValue: app.appName,
                            seq: "1",
                        },
                        {
                            optionName: "appCode",
                            optionValue: app.appCode,
                            seq: "2",
                        },
                        {
                            optionName: "name",
                            optionValue: page.name,
                            seq: "3",
                        },
                        {
                            optionName: "code",
                            optionValue: page.code,
                            seq: "4",
                        },
                        {
                            optionName: "action",
                            optionValue: page.action,
                            seq: "5",
                        },
                    ],
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success("保存成功")
                this.$refs.model.close()
                this.$refs.comp_table.refresh()
            })
        },

        /**
         * 选择机构
         */
        onSubmitOrg(evt) {
            this.selected_org = evt.value?.[0]
        },

        /**
         * 选择应用
         */
        onChangeSelection(evt) {
            this.page_data = evt.value?.[0]?.appPageVoList || []
            // 清除勾选
            this.$refs.page_table.clearSelecteds()
        },

        /**
         * 关闭弹窗
         */
        onCloseModal() {
            this.selected_org = null
            this.edit_org = null
            this.page_data = []
            this.$refs.app_table.reset()
            this.$refs.page_table.reset()
        },

        /**
         * 搜索页面
         */
        onPageDataBefore(data) {
            this.$refs.page_table.keyword(data.value)
        },
    },
}
</script>
<style lang="less">
.modal-operate-btn {
    cursor: pointer;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2faaf7;
    color: #fff;
    border-radius: 4px;
}

.organization-home-set-up {
    .model-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .dimension-box {
            margin: 15px 10px 5px 10px;
            padding-bottom: 15px;
            border-bottom: 1px solid #f3f3f3;
            display: flex;
            align-items: center;

            .select-box {
                height: 40px;
                width: 300px;
                line-height: 40px;
                padding: 0 10px;
                border-radius: 4px;
                border: 1px solid #e3e3e3;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;

                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .empty {
                    color: #999;
                }

                .ivu-icon {
                    margin-left: 20px;
                    flex-shrink: 0;
                }
            }
        }
    }

    .model-table {
        display: flex;
        flex-grow: 1;

        .table-item {
            position: relative;
            flex: 1;
        }
    }
}
</style>
